import { getPlatform } from './get-platform';
import { isRunningOnMobileApp } from './is-mobile';
import { CrossPlatformPlatform } from './cross-platform-platform';

class CrossPlatformClass {
    public get platform(): CrossPlatformPlatform {
        return getPlatform();
    }

    public get isRunningOnMobileApp(): boolean {
        return isRunningOnMobileApp();
    }
}

// ! CrossPlatform requires wrapping in suppressHydrationWarning
export const CrossPlatform = new CrossPlatformClass();
