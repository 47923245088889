import { Capacitor } from '@capacitor/core';
import { CrossPlatformPlatform } from './cross-platform-platform';

export function getPlatform(): CrossPlatformPlatform {
    const platform = Capacitor.getPlatform();
    switch (platform) {
        case 'ios': return CrossPlatformPlatform.ios;
        case 'android': return CrossPlatformPlatform.android;
        case 'web': return CrossPlatformPlatform.web;
        default: throw new Error(`Unknown platform: ${platform}`);
    }
}
