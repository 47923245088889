'use client';

import { ReactNode, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Typography,
    Snackbar,
    Slide,
    AlertContextType,
    AlertType,
} from '@deltasierra/react/components/core';
import { captureException } from '@sentry/nextjs';
import { AlertContext } from '../../contexts/AlertContext';

type AlertProps = {
    message?: string;
    severity: AlertType;
    title: string;
};

export const AlertProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [alertMessage, setAlertMessage] = useState<AlertProps | null>(null);

    const contextValue: AlertContextType = {
        showAlert: (severity, title, message, error) => {
            setAlertMessage({
                message,
                severity,
                title,
            });
            if (error) {
                captureException(error);
            }
        },
    };

    const handleClose = (_: unknown, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertMessage(null);
    };

    return (
        <AlertContext.Provider value={contextValue}>
            {children}
            <Snackbar
                TransitionComponent={props => (
                    <Slide
                        {...props}
                        direction="up"
                        easing={{
                            enter: 'cubic-bezier(0, 2, .5, 1)',
                        }}
                    />
                )}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                autoHideDuration={5000}
                open={alertMessage !== null}
                onClose={handleClose}
            >
                <Box sx={{ minWidth: '20rem' }}>
                    <Alert severity={alertMessage?.severity} variant="filled" onClose={handleClose}>
                        <AlertTitle>{alertMessage?.title}</AlertTitle>
                        {alertMessage?.message && <Typography noWrap>{alertMessage.message}</Typography>}
                    </Alert>
                </Box>
            </Snackbar>
        </AlertContext.Provider>
    );
};

AlertProvider.displayName = 'AlertProvider';
