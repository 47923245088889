import { EMAIL_ALLOWED_MIME_TYPES, EMAIL_MAX_FILE_SIZE_BYTES } from '@deltasierra/react/components/core';
import {
    AssetLibrary,
    AssetLibraryUploadDestination,
    ResultAssetFile,
    TAB_BROWSER,
    TAB_UPLOAD,
    useAssetLibraryBrowse,
    useAssetLibraryUpload,
} from '@deltasierra/react/features/asset-library-modal';
import { useTranslations } from '@deltasierra/translations/react';
import { useEffect, useState } from 'react';
import { useAlert } from '../hooks';

export type AssetLibraryModalContainerProps = {
    clientId: string;
    disableUploadsTab: boolean;
    locationId?: string;
    onAssetSelected: (asset: ResultAssetFile) => void;
    onCloseModal: () => void;
    preUploadFile: File | null;
    uploadDestination: AssetLibraryUploadDestination;
};

export function AssetLibraryModalContainer({
    clientId,
    disableUploadsTab,
    locationId,
    onAssetSelected,
    onCloseModal,
    preUploadFile,
    uploadDestination,
}: AssetLibraryModalContainerProps): JSX.Element {
    const t = useTranslations('AssetLibrary');
    const { showAlert } = useAlert();
    const [currentTabIndex, setCurrentTabIndex] = useState(TAB_BROWSER);
    const {
        assets,
        collections,
        currentCollection,
        currentFolders,
        folderActions,
        isLoadingAssets,
        isLoadingCollections,
        isOpenCollection,
        onChangeSearch,
        onClickBreadcrumb,
        onClickHeader,
        onClickSearch,
        onClickSelectCollection,
        onClickThumbnail,
        search,
        watchElementRef,
    } = useAssetLibraryBrowse({ clientId, locationId, onAssetSelected, onCloseModal, showAlert, t });
    const {
        isCheckedConfirmRead,
        isCheckedNeverAgain,
        isLoadingUpload,
        isOpenPermissionModal,
        onChangeAssets,
        onChangeConfirmRead,
        onChangeNeverAgain,
        onClickUploadCancel,
        onClickUploadNext,
    } = useAssetLibraryUpload({
        onCloseModal,
        showAlert,
        uploadDestination,
    });

    useEffect(() => {
        if (preUploadFile) {
            setCurrentTabIndex(TAB_UPLOAD);
            onChangeAssets(preUploadFile);
        }
    }, [onChangeAssets, preUploadFile]);

    return (
        <AssetLibrary
            allowedTypes={EMAIL_ALLOWED_MIME_TYPES}
            assets={assets}
            collections={collections}
            currentCollection={currentCollection}
            currentFolders={currentFolders}
            currentTabIndex={currentTabIndex}
            disableUploadsTab={disableUploadsTab}
            folderActions={folderActions}
            isCheckedConfirmRead={isCheckedConfirmRead}
            isCheckedNeverAgain={isCheckedNeverAgain}
            isLoadingAssets={isLoadingAssets}
            isLoadingCollections={isLoadingCollections}
            isLoadingUpload={isLoadingUpload}
            isOpenCollection={isOpenCollection}
            isOpenPermissionModal={isOpenPermissionModal}
            maxFileSize={EMAIL_MAX_FILE_SIZE_BYTES}
            search={search}
            sortByOptions={[]}
            t={t}
            viewOptions={[]}
            watchElementRef={watchElementRef}
            onChangeAssets={onChangeAssets}
            onChangeConfirmRead={onChangeConfirmRead}
            onChangeNeverAgain={onChangeNeverAgain}
            onChangeSearch={onChangeSearch}
            onChangeTabs={(_, newTab) => setCurrentTabIndex(newTab)}
            onClickBreadcrumb={onClickBreadcrumb}
            onClickClose={onCloseModal}
            onClickHeader={onClickHeader}
            onClickSearch={onClickSearch}
            onClickSelectCollection={onClickSelectCollection}
            onClickSortBy={() => null}
            onClickThumbnail={onClickThumbnail}
            onClickUploadCancel={onClickUploadCancel}
            onClickUploadNext={onClickUploadNext}
            onClickView={() => null}
        />
    );
}
AssetLibraryModalContainer.displayName = 'AssetLibraryModalContainer';
