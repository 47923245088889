import { ReactNode } from 'react';
import { AlertProvider } from './AlertProvider';
import { AssetLibraryModalProvider } from './AssetLibraryModalProvider';
import { ApolloProvider } from './ApolloProvider';
import { CrossPlatformProvider } from './CrossPlatformProvider';

/**
 * Common providers include all remaining providers that are required for the app to function correctly,
 * excluding some crucial providers which are separately defined in `AppRootLayout` or either of the root layout/s.
 */
export function CommonProviders({ children }: { children: ReactNode }): JSX.Element {
    return (
        <CrossPlatformProvider>
            <AlertProvider>
                <ApolloProvider>
                    <AssetLibraryModalProvider>{children}</AssetLibraryModalProvider>
                </ApolloProvider>
            </AlertProvider>
        </CrossPlatformProvider>
    );
}
CommonProviders.displayName = 'CommonProviders';
