'use client';

import { ReactNode, Suspense } from 'react';
import { CommonProviders } from '../../components/core/CommonProviders';

/**
 * This is the app layout component, this should define the rest of the layout we need in the app that
 * isn't necessarily the absolute minimum.
 *
 * For now this just provide the rest of the shared providers required across the app
 * and a root suspense. In future this should also define the shared layout across
 * authenticated and non-authenticated routes (if any).
 *
 */
export default function AppLayout({ children }: { children: ReactNode }): JSX.Element {
    return (
        <Suspense fallback="">
            <CommonProviders>{children}</CommonProviders>
        </Suspense>
    );
}
AppLayout.displayName = 'AppLayout';
