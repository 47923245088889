'use client';

import { ApolloClient, ApolloProvider as ApolloProviderInitial, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphqlErrorCodes } from '@deltasierra/graphql/errors';
import { FRONTEND_GRAPHQL_POSSIBLE_TYPES, FRONTEND_GRAPHQL_TYPE_POLICIES } from '@deltasierra/frontend/graphql';
import { ReactNode, useMemo } from 'react';
import { useAppWebRouter } from '../../hooks';

export function ApolloProvider({ children }: { children: ReactNode }): JSX.Element {
    const AppWebRouter = useAppWebRouter();

    const client = useMemo(
        () =>
            new ApolloClient({
                cache: new InMemoryCache({
                    addTypename: true,
                    possibleTypes: FRONTEND_GRAPHQL_POSSIBLE_TYPES,
                    typePolicies: FRONTEND_GRAPHQL_TYPE_POLICIES,
                }),
                link: from([
                    /**
                     * !IMPORTANT!
                     * Do not re-throw errors inside this Apollo link, the error needs
                     * to be passed through the link so that suspense queries error out
                     * correctly and the error handler can be reached.
                     *
                     * If we do throw an error here the error will be thrown outside of the
                     * react error boundaries tree and it will return an infinitely waiting suspense
                     * fallback.
                     */
                    onError(({ graphQLErrors }) => {
                        if (graphQLErrors) {
                            graphQLErrors.forEach(error => {
                                if (error.extensions?.code === GraphqlErrorCodes.UNAUTHORIZED) {
                                    const redirectUrl = AppWebRouter.get('/login');
                                    location.replace(redirectUrl);
                                    // eslint-disable-next-line no-console
                                    console.log(`User not logged in, redirecting to ${redirectUrl}`);
                                }
                            });
                        }
                    }),
                    new HttpLink({
                        credentials: 'include',
                        uri: AppWebRouter.get('/graphql'),
                    }),
                ]),
            }),
        [AppWebRouter],
    );

    return <ApolloProviderInitial client={client}>{children}</ApolloProviderInitial>;
}

ApolloProvider.displayName = 'ApolloProvider';
