'use client';

import { ReactNode } from 'react';
import { CrossPlatform } from '@deltasierra/frontend/cross-platform';
import { CrossPlatformContext } from '../../contexts';

export function CrossPlatformProvider({ children }: { children: ReactNode }): JSX.Element {
    return <CrossPlatformContext.Provider value={CrossPlatform}>{children}</CrossPlatformContext.Provider>;
}

CrossPlatformProvider.displayName = 'CrossPlatformProvider';
